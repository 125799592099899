<template>  
  <v-container class="mt-5">
    <v-row >  
      <v-col cols="12" lg="8">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            Nuevo artículo

            <v-spacer></v-spacer>
            <!-- BTN ATRAS -->
            <v-btn 
              small
              class="ma-2" 
              color="primary" 
              :to="{name:'catproductos'}" 
              dark>Atras
            </v-btn>

            <!-- BTN GUARDAR -->
            <v-btn 
              v-if="existe == false"
              small 
              dark 
              class="ma-2" 
              @click.prevent="agregar"
              color="secondary">Guardar
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="5" lg="6" xl="5">
                <v-text-field
                  clearable
                  label="Ingresar Código"
                  v-model="codigo"
                  dense
                  validate-on-blur
                  class="pa-0"  
                  outlined hide-details 
                  autofocus
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col  cols="12">
                <v-text-field
                  v-if="existe == false"
                  label="Nombre del artículo"
                  v-model="nomart"
                  outlined 
                  hide-details 
                  dense
                  rows="3"
                ></v-text-field>
              </v-col>

              <v-col  cols="12">
                <v-textarea 
                  v-if="existe == false"
                  v-model = "ubicacion"
                  outlined
                  dense
                  rows="3"
                  label="Ubicación"
                >
                </v-textarea>
              </v-col>

              <v-col  cols="12">
                <v-textarea 
                  v-if="existe == false"
                  v-model = "descrip"
                  outlined
                  dense
                  rows="3"
                  label="Descripción corta"
                >
                </v-textarea>
              </v-col>

              <v-col  cols="12">
                <v-textarea 
                  v-if="existe == false"
                  v-model = "descripLarga"
                  outlined
                  dense
                  label="Descripción larga"
                >
                </v-textarea>
              </v-col>
              <v-col cols="12">
                <v-alert type="error" :value="alertaInput">
                  {{alerta}}
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
             
    <!-- SNACK BAR -->
    <v-snackbar
      v-model="snackbar"
      :timeout="8000"
      top
      :color="color" 
      >
      {{text}}
      <v-btn
        color="white"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';

  export default {
    data () {
      return {
        familias:[],
        familia: null,
        existe: true,
        subcategorias:[],
        subcategoria:null,
        categorias: [],
        categoria:null,

        snackbar: false,
        text:'',
        color:'',

        codigo:'',
        result2:'',
        nomart:'',
        sal:'',
        lab:'',

        alerta:'',
        alertaInput: false,

        descrip:'',
        descripLarga:'',
        ubicacion:'',

      }
    },

    created(){
      this.$http.get('familias.activo').then(response=>{
        this.familias = response.data
      }).catch(error=>{
        console.log('error',error)
      })
    },

    computed: {
      validaInfo(){
        return  this.nomart      !==  '' && 
                this.descrip     !==  '' &&       
                this.ubicacion   !==  '' &&       
                this.codigo      !==  '' 
      },
    },
      
    watch:{
      codigo(){
        if(this.codigo != ''){
          this.$http.get('articulos.codigo/'+ this.codigo).then(response=>{
            if(response.data.length > 0){
              this.existe = true
              this.text = 'Producto ya existente'
              this.snackbar = true
            }else{
              this.existe = false
            }
          }).catch(error=>{
            this.existe = false
            console.log('error',error)
          })
        }else{this.existe = true}
      },
    },

    methods:{

      agregar(){
        this.alerta = ''
        if(!this.validaInfo){
          if(!this.codigo){
            this.alerta = `No has asignado un codigo al articulo`
          }

          if(!this.nomart){
            this.alerta = this.alerta +`
            No has asignado un nombre al articulo`
          }

          if(!this.descrip){
            this.alerta = this.alerta +`
            No has asignado un compuesto  al articulo`
          }

          this.alertaInput = true
        }else{
          var payload = {
            nomart:           this.nomart,
            codigo:           this.codigo,
            descrip:          this.descrip,
            ubicacion:        this.ubicacion,
            descripLarga:     this.descripLarga,
          }
          this.$http.post('articulos.add', payload).then(response=>{
            this.text  = 'Artículo agregado'
            this.color = 'success'
            this.snackbar = true
            setTimeout(() => (this.$router.push({name: 'catproductos'})), 1500)
          }).catch(error=>{
            console.log(error)
            this.text  = error.body.message
            this.color = 'error'
            this.snackbar = true
          })
        }
      }
    }
  }
</script>